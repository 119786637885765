import "./Orders.css";
import React, { useState } from "react";
import { Col, Container, Row, Table, Button } from "react-bootstrap";
import { TbArrowsRightLeft } from "react-icons/tb";
import { BiDollar } from "react-icons/bi";

function Orders() {
  const orders = JSON.parse(sessionStorage.getItem("orders"));
  const [data, setData] = useState(orders);

  const deleteOrder = (id) => {
    let localData = sessionStorage.getItem("orders");
    let newList = JSON.parse(localData);
    let index = newList.findIndex((item) => item.id === id);
    newList.splice(index, 1);
    sessionStorage.setItem("orders", JSON.stringify(newList));

    // var results = orders.slice(id);
    // console.log(id);
    // sessionStorage.setItem("orders", JSON.stringify(results));
    // setData(results);
    // console.log(id);
    setData((items) => items.filter((item) => item.id !== id));
  };

  return (
    <Container className="orders-container">
      <h1 style={{ textAlign: "center" }}>Purchased products information</h1>

      <Row className="orders-row">
        <Col className="orders-col">
          <div>
            {!orders || data.length === 0 ? (
              <h2 style={{ marginTop: "50px", paddingTop: "65px" }}>
                there are no orders
              </h2>
            ) : (
              <>
                <Button
                  variant="danger"
                  onClick={() => {
                    setData(sessionStorage.removeItem("orders"));
                  }}
                >
                  Remove all orders
                </Button>
                <br />
                <small className="arrows-icon">
                  <TbArrowsRightLeft size="1.3rem" />
                </small>
                <Table className="orders-table" responsive="sm" bordered>
                  <thead>
                    <tr style={{ textAlign: "center" }}>
                      <th>Num</th>
                      <th>Image</th>
                      <th>Product Name</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total price</th>
                      <th>Time Order</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((order, i) => {
                      const {
                        id,
                        category,
                        title,
                        price,
                        quantity,
                        time,
                        date,
                      } = order;
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            <img
                              src={category.image}
                              alt="order_img"
                              className="order-image"
                            />
                          </td>
                          <td>{title}</td>
                          <td>
                            {Math.round(price - (price * 2) / 100)} <BiDollar />
                          </td>
                          <td>{quantity}</td>
                          <td>
                            {Math.round((price - (price * 2) / 100) * quantity)}{" "}
                            <BiDollar />
                          </td>
                          <td>{time}</td>
                          <td>{date}</td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => deleteOrder(id)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Orders;
