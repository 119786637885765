import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";

function SimilarProductsImages(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <h6 className="remove-basket-btn" onClick={handleShow}>
        more images
      </h6>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Body>
          <Carousel variant="dark">
            <Carousel.Item>
              <img
                style={{ width: "100%", height: "100%" }}
                src={props.data[0]}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                style={{ width: "100%", height: "100%" }}
                src={props.data[1]}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                style={{ width: "100%", height: "100%" }}
                src={props.data[2]}
                alt="First slide"
              />
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <h5
            variant="secondary"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          >
            Close
          </h5>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SimilarProductsImages;
