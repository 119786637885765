import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Payment from "./Payment";

function StripeContainer() {
  const API_STRIPE_KEY =
    "pk_test_51LlZJWENrkhP6vB3Rzoa8hBoawV5jfi39IXbuW3N22HHWg90iYwEz463ALg7WIzOBcEJxoYpqAjlhBngam3IRpQG00ssJtx3ao";
  const promiseStripe = loadStripe(API_STRIPE_KEY);

  return (
    <Elements stripe={promiseStripe}>
      <Payment />
    </Elements>
  );
}

export default StripeContainer;
