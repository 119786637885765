import "./ProductDetails.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../APIs/BaseApi";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { useCart } from "react-use-cart";
import { MdStarRate } from "react-icons/md";
import ReactImageMagnify from "react-image-magnify";

function ProductDetails() {
  const { productId } = useParams();
  const [product, setProduct] = useState({
    title: "",
    price: "",
    description: "",
    category: {
      // old api category : ""
      name: "",
      image: "",
    },
    images: [],

    //old api

    // image: "",
    // rating: {
    //   rate: "",
    // },
  });

  const img = product.category.image;

  useEffect(() => {
    const getProductById = async () => {
      const response = await axios.get(`${baseUrl}/${productId}`);
      setProduct(response.data);
    };
    getProductById();
  }, []);

  // add to cart

  const { addItem } = useCart();

  const addToCart = () => {
    addItem(product);
  };

  return (
    <>
      <Container>
        <Row className="product-details-row">
          <Col>
            <div className="grand-parent-img-pd">
              <div className="parent-img-pd">
                <ReactImageMagnify
                  className="img-fluid  image-zoom"
                  {...{
                    smallImage: {
                      alt: "Wristwatch by Ted Baker London",
                      isFluidWidth: true,
                      src: img,
                      width: 250,
                      height: 300,
                    },
                    largeImage: {
                      src: img,
                      width: 1500,
                      height: 1500,
                      posation: "relative",
                    },
                  }}
                />
                {/* <Card.Img
                  variant="top"
                  className="img-fluid img-pd"
                  src={product.image}
                /> */}
              </div>
            </div>
          </Col>
          <Col className="text-col-pd">
            <Card className="card-pd" key={product.id}>
              <Card.Body className="card-text-pd">
                <Card.Title>Product Name : {product.title}</Card.Title>
                <Card.Title>Price : {Math.round(product.price)} $</Card.Title>
                <Card.Title>Category : {product.category.name}</Card.Title>
                {/* <Card.Title>
                  Rating : {product.rating.rate} <MdStarRate />
                </Card.Title> */}
                <Card.Text className="description-pd">
                  {product.description}
                </Card.Text>
                <Button variant="primary" onClick={() => addToCart()}>
                  Add to cart
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* <Row className="payment-row-2">
              <Col>
                <div className="image-div">
                  <img
                    src={product.image}
                    alt="raafat"
                    // className="img-fluid"
                  />
                </div>
                <div className="text-div">
                  <p>{product.description}</p>
                  <h5>Quantity : {product.quantity}</h5>

                 
                  <div className="del-save">
                   
                  </div>
                  <h5>{item.title}</h5>
                </div>
              </Col>
            </Row> */}
      </Container>
    </>
  );
}

export default ProductDetails;
