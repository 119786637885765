import "./Header.css";
import React from "react";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import { BiCart, BiLogIn } from "react-icons/bi";
import { GoSignIn } from "react-icons/go";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import { useNavigate } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";

function Header() {
  const { totalItems } = useCart();
  const navigate = useNavigate();

  const isAuth = localStorage.getItem("token");

  const logout = () => {
    console.log(isAuth);
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <Navbar className="navbar-div" expand="md" fixed="top">
      <Container>
        <Link to="/">
          <Navbar.Brand>
            <b>eCommerce</b>
          </Navbar.Brand>
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto links">
            <Link to="/">Home</Link>
            <Link to="/cart">
              <span>Cart</span>
              <BiCart size="1.4rem" style={{ color: "chartreuse" }} />
              <span className="cart-number">{totalItems} </span>
            </Link>
            <Link to={isAuth ? "/orders" : "/orders/login"}>Orders</Link>
            {localStorage.getItem("token") ? (
              <span className="logout-icon" onClick={logout}>
                <AiOutlineLogout size="1.2rem" />
              </span>
            ) : (
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                <Dropdown.Menu className="dd">
                  <Dropdown.Item>
                    <Link to="/login" className="link-db">
                      Login
                      <BiLogIn size="1.4rem" />
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Link to="/register" className="link-db">
                      Register
                      <GoSignIn size="1.4rem" />
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
