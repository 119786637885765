import "./Products.css";
import React from "react";
import { Button, Card } from "react-bootstrap";
import { useCart } from "react-use-cart";
import { Link } from "react-router-dom";

import { FaHandPointRight } from "react-icons/fa";

function Products(props) {
  const { id, title, price, description, image, category } = props.data;
  const { addItem } = useCart();

  const addToCart = () => {
    addItem(props.data);
    // localStorage.setItem("save-for-later", "[]");
  };

  return (
    <>
      <Card style={{ width: "17rem", height: "auto" }} key={id}>
        <div className="div-img">
          <div style={{ width: "17rem" }}>
            <Link to={`/products/${id}`}>
              <Card.Img
                variant="top"
                className="img-fluid product"
                src={category.image}
              />
            </Link>
          </div>
        </div>
        <Card.Body>
          <Card.Title className="product-name">
            <Link to={`/products/${id}`}>{title}</Link>
          </Card.Title>
          <Card.Title>
            {Math.round(price)} ${" "}
            <FaHandPointRight
              style={{ color: "#ffb300", marginRight: "4px" }}
            />
            <span className="discount"> -2%</span>
          </Card.Title>
          <Card.Text className="description">{description}</Card.Text>
          <Button variant="primary" onClick={() => addToCart()}>
            Add To Cart
          </Button>
        </Card.Body>
      </Card>
    </>
  );
}

export default Products;
