import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// start imoprt all components
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Cart from "./Components/Cart/Cart";
import Orders from "./Components/Orders/Orders";
import Login from "./Components/Login/Login";
import Register from "./Components/Registration/Register";
import ProductDetails from "./Components/ProductDetails/ProductDetails";
import StripeContainer from "./Components/Payment/StripeContainer";
import Footer from "./Components/Footer/Footer";
// end import components
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="page-container">
      <div className="router-div">
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            {/* start cart routes */}
            <Route path="/cart" element={<Cart />} />
            <Route path="/cart/register" element={<Register />} />
            <Route path="/cart/login" element={<Login />} />
            {/* end cart routes */}

            {/* start payment routes */}
            <Route path="/payment" element={<StripeContainer />} />
            <Route path="/payment/register" element={<Register />} />
            <Route path="/payment/login" element={<Login />} />
            {/* end payment routes */}
            <Route path="/orders" element={<Orders />} />
            <Route path="/orders/login" element={<Login />} />
            <Route path="/products/:productId" element={<ProductDetails />} />
          </Routes>
          <ToastContainer
            position="top-right"
            autoClose={1500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default App;
