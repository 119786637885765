import React from "react";
import "./Register.css";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import { RegisterSchema } from "../Schemas/Register";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
};

export default function Register() {
  let navigate = useNavigate();
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: RegisterSchema,
      onSubmit: (values) => {
        if (values.email !== sessionStorage.getItem("email")) {
          localStorage.setItem("email", values.email);
          localStorage.setItem("password", values.password);
          localStorage.setItem("first_name", values.first_name);
          localStorage.setItem("last_name", values.last_name);
          sessionStorage.setItem("email", values.email);
          toast.success("Created account successfully");
          if (document.location.pathname === "/cart/register") {
            navigate("/cart/login");
          } else if (document.location.pathname === "/payment/register") {
            navigate("/payment/login");
          } else {
            navigate("/login");
          }
        } else {
          // alert("This E-mail already exists!");
          toast.info("Sorry, this E-mail already exists!");
        }
      },
    });

  return (
    <Container className="form-container">
      <Row>
        <Col>
          <Form className="form" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.first_name && touched.first_name ? (
                <p className="error">{errors.first_name}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.last_name && touched.last_name ? (
                <p className="error">{errors.last_name}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email ? (
                <p className="error">{errors.email}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.password && touched.password ? (
                <p className="error">{errors.password}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                name="confirm_password"
                value={values.confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.confirm_password && touched.confirm_password ? (
                <p className="error">{errors.confirm_password}</p>
              ) : null}
            </Form.Group>
            <Button type="submit" className="rgister-btn">
              Register
            </Button>
            <br />
            <small>
              have you account?
              <Link className="redirectLogin" to="/login">
                login
              </Link>
            </small>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
