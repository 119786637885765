import "./Home.css";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { baseUrl } from "../APIs/BaseApi";
import Products from "../Products/Products";
import { toast } from "react-toastify";
// import axios from "axios"
// import { useCart } from "react-use-cart"
// import SearchFilter from "react-filter-search";

function Home() {
  const [data, setAllData] = useState([]);
  const [items, setItems] = useState(data);
  const [searchInput, setSearchInput] = useState("");
  const [searchPrice, setSearchPrice] = useState("");
  //  let componentDidMounted = true

  //  useEffect(()=>{
  //   const getAllDataFromApi = async ()=>{
  //     const response = await fetch(api)
  //     if(componentDidMounted){
  //       setAllData(await response.clone().json())
  //       setItems(await response.json())
  //     }
  //     return ()=>{
  //       componentDidMounted = false
  //     }
  //   }
  //   getAllDataFromApi()
  //  },[])

  // handle search input

  const handleSearchInput = ($e) => {
    setSearchInput($e.target.value);
  };

  useEffect(() => {
    const getAllData = async () => {
      const response = await fetch(baseUrl)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {
          console.log(err);
        });
      setAllData(response);
      setItems(response);
    };

    getAllData();
  }, []);

  // second way using axios

  // useEffect(()=>{
  //    const  getAllData = ()=>{
  //       axios.get(api).then(res=>{
  //            setAllData(res.data)
  //            setItems(res.data)
  //       }).catch(err=>{
  //         console.log(err)
  //       })

  //    }
  //    getAllData()
  // },[])

  // filter by category

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getAllCategories = async () => {
      const response = await fetch(
        "https://fakestoreapi.com/products/categories"
      )
        .then((res) => {
          return res.json();
        })
        .catch((error) => {
          console.log(error);
        });
      setCategories(response);
    };
    getAllCategories();
  }, []);

  const filterCategory = (category) => {
    const getByCateg = data.filter(
      (product) => product.category.name === category
    );
    setItems(getByCateg);
  };

  // filter by price

  const handleSelectPrice = ($e) => {
    setSearchPrice($e.target.value);
  };

  return (
    <>
      <Container className="categories">
        <Row>
          <Col>
            <h3 className="title">Choose your Product</h3>
          </Col>
        </Row>

        {/* <Row>
          <Col className="buttons">
            <Button className="filter-btn" onClick={() => setItems(data)}>
              All
            </Button>
            {categories.map((category) => {
              return (
                <Button
                  className="filter-btn"
                  onClick={() => filterCategory(category)}
                >
                  {category}
                </Button>
              );
            })}
          </Col>
        </Row> */}

        <Row>
          <Col className="buttons">
            <Button className="filter-btn" onClick={() => setItems(data)}>
              All
            </Button>
            <Button
              className="filter-btn"
              onClick={() => filterCategory("Electronics")}
            >
              Electronics
            </Button>
            <Button
              className="filter-btn"
              onClick={() => filterCategory("Clothes")}
            >
              Clothes
            </Button>
            <Button
              className="filter-btn"
              onClick={() => filterCategory("Shoes")}
            >
              Shoes
            </Button>
            <Button
              className="filter-btn"
              onClick={() => filterCategory("Others")}
            >
              Others
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              type="text"
              className="search-input"
              placeholder="Search......."
              value={searchInput}
              onChange={handleSearchInput}
            />
          </Col>
          <Col>
            <div className="price-filter">
              <span>Peice</span>
              <Form.Select
                className="select"
                aria-label="Default select example"
                value={searchPrice}
                onChange={handleSelectPrice}
              >
                <option className="option" value="0">
                  ALl Prices
                </option>
                <option className="option" value="20">
                  Up to $ 20
                </option>
                <option className="option" value="80">
                  Up to $ 80
                </option>
                <option className="option" value="100">
                  Up to $ 100
                </option>
                <option className="option" value="200">
                  Up to $ 200
                </option>
                <option className="option" value="300">
                  Up to $ 300
                </option>
                <option className="option" value="500">
                  Up to $ 500
                </option>
                <option className="option" value="600">
                  Up to $ 600
                </option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <Row className="row-category-name">
          {items
            .filter((val) => {
              if (searchInput === "") {
                return val;
              } else if (
                val.title.toLowerCase().includes(searchInput.toLowerCase()) ||
                val.description
                  .toLowerCase()
                  .startsWith(searchInput.toLowerCase())
              ) {
                return val;
              }
            }) // filter by price
            .filter((product) => {
              if (product.price >= searchPrice) {
                return product;
              }
            })
            .map((product, index) => {
              // const {id, title, price, description, image} = product
              return <Products data={product} key={index} />;
            })}
          {/* 
          <SearchFilter
            value={searchInput}
            data={items}
            renderResults={(results) => (
              <Row className="justify-content-center">
                {results.map((item, i) => {
                  return <Products data={item} key={i} />;
                })}
              </Row>
            )}
          /> */}
        </Row>
      </Container>
    </>
  );
}

export default Home;
