export default function validationLogin(formValue) {
  let errors = {};
  if (!formValue.email) {
    errors.email = "Email is required";
  } else if (!localStorage.getItem("email")) {
    errors.email = "This email is not registered";
  } else if (formValue.email !== localStorage.getItem("email")) {
    errors.email = "Invalid E-mail";
  }

  if (!formValue.password) {
    errors.password = "Password is required";
  } else if (!localStorage.getItem("password")) {
    errors.password = "This password is not registered";
  } else if (formValue.password !== localStorage.getItem("password")) {
    errors.password = "Invalid Password";
  }

  return errors;
}
