import "./Login.css";
import React, { useState, useRef } from "react";
import { Container, Form, Button } from "react-bootstrap";
import validationLogin from "./validation";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";

function Login() {
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const focusRef = useRef();
  const navigate = useNavigate();

  // on change inputs
  const handleChange = ($e) => {
    setFormValue({
      ...formValue,
      [$e.target.name]: $e.target.value,
    });
  };

  // handle login function
  const handleLoginForm = ($e) => {
    $e.preventDefault();
    const regisEmail = localStorage.getItem("email");
    const regisPassword = localStorage.getItem("password");
    setErrors(validationLogin(formValue));
    if (
      regisEmail === formValue.email &&
      regisPassword === formValue.password
    ) {
      toast.success("Login Successfully");
      console.log("Successfully Login");
      localStorage.setItem("token", JSON.stringify(regisEmail));
      if (document.location.pathname === "/orders/login") {
        navigate("/orders");
      } else if (document.location.pathname === "/payment/login") {
        navigate("/payment");
      } else if (document.location.pathname === "/cart/login") {
        navigate("/cart");
      } else if (document.location.pathname === "/login") {
        navigate("/");
      } else {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    focusRef.current.focus();
  }, []);

  return (
    <Container className="form-container">
      <Form className="form">
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            ref={focusRef}
            value={formValue.email}
            onChange={handleChange}
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter Password"
            name="password"
            value={formValue.password}
            onChange={handleChange}
          />
          {errors.password && <p className="error">{errors.password}</p>}
        </Form.Group>
        <Button className="login-btn" type="submit" onClick={handleLoginForm}>
          Login
        </Button>
        <br />
        <small>
          you haven't account?
          <Link to="/register" className="redirectRegister">
            create account
          </Link>
        </small>
      </Form>
    </Container>
  );
}

export default Login;
