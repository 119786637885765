import * as Yup from "yup";

export const RegisterSchema = Yup.object({
  first_name: Yup.string()
    .min(3, "First Name must be geater than 3 characters")
    .max(20, "First Name must be less  than 20 characters")
    .required("First Name is required"),
  last_name: Yup.string()
    .min(3, "Last Name must be geater than 3 characters")
    .max(20, "Last Name must be less  than 20 characters")
    .required("Last Name is required"),
  email: Yup.string()
    .email("You must enter a valid email")
    .required("E-mail is required"),
  password: Yup.string()
    .min(6, "Password must be geater than 6 characters")
    .max(25, "Password must be less  than 25 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .required("you must confirm your password")
    .oneOf([Yup.ref("password"), null], "Password must be matches"),
});
