import "./Cart.css";
import React from "react";
import { useCart } from "react-use-cart";
import { Button, Container, Row, Col } from "react-bootstrap";
import { FaPlus, FaMinus } from "react-icons/fa";
import { BiDollar } from "react-icons/bi";
import emptyCartImg from "../../images/cart-empty.png";
import { Link } from "react-router-dom";
// import { baseUrl } from "../APIs/BaseApi";
// import axios from "axios";
// import SaveForLater from "../SaveForLater/SaveForLater";
import SimilarProductsImages from "./SimilarProductsImages";

function Cart() {
  const isAuth = localStorage.getItem("token");
  // const [savedProduct, setSavedProduct] = useState({});
  // const [data, setData] = useState([]);

  const {
    isEmpty,
    items,
    totalUniqueItems,
    totalItems,
    cartTotal,
    updateItemQuantity,
    emptyCart,
    removeItem,
  } = useCart();

  // get  similar product

  // start save for later function

  // const getSavedData = () => {
  //   const data = JSON.parse(localStorage.getItem("save-for-later"));
  //   setSavedProduct(data);
  // };

  // -------------- old function----------------------------

  // useEffect(() => {
  //   const getSavedData = () => {
  //     const products = JSON.parse(localStorage.getItem("save-for-later"));
  //     setSavedProduct(products);
  //   };
  //   getSavedData();
  // }, []);

  // const saveForLater = async (id) => {
  //   const response = await fetch(`${baseUrl}/${id}`)
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   const item = JSON.parse(localStorage.getItem("save-for-later"));
  //   item.push(response);
  //   localStorage.setItem("save-for-later", JSON.stringify(item));
  //   removeItem(id);
  // };

  // -------------- old function--------------------------

  // const saveForLater = async (id) => {
  //   const response = await axios.get(`${baseUrl}/${id}`);
  //   data.push(response.data);
  //   setData(data);
  //   console.log(data);
  //   removeItem(id);
  // };

  return (
    <>
      <h1>Cart Products</h1>
      {isAuth && !isEmpty && (
        <div className="state">
          <h4>Cart Stats</h4>
          <div>
            All Price :{" "}
            <h5>
              {Math.round(cartTotal - (cartTotal * 2) / 100)} <BiDollar />
            </h5>
          </div>
          <div>
            Total Products quantity:{" "}
            <h5>
              {totalItems} {totalItems > 1 ? "items" : "item"}
            </h5>
            Number Products :{" "}
            <h5>
              {" "}
              {totalUniqueItems} {totalUniqueItems > 1 ? "products" : "product"}
            </h5>
          </div>
          <Link to={isAuth ? "/payment" : "/payment/login"}>
            <Button className="checkout-btn">Proceed to checkout</Button>
          </Link>
        </div>
      )}

      {isAuth && !isEmpty && (
        <button
          onClick={() => emptyCart()}
          className="position-fixed clear-all"
        >
          Clear Cart
        </button>
      )}

      {isEmpty && isAuth && (
        <div className="div-cart-img">
          <img
            src={emptyCartImg}
            className="img-fluid  empty-image"
            alt="Cart Empty"
          />
        </div>
      )}

      {!isAuth && (
        <Container>
          <Row className="notAuthAndEmpty-row">
            <Col className="notAuthAndEmpty-col">
              <div className="notAuthAndEmpty-img">
                <img
                  style={{ width: "250px", height: "250px" }}
                  src="https://m.media-amazon.com/images/G/01/cart/empty/kettle-desaturated._CB445243794_.svg"
                  alt="you aren't user and cart empty"
                />
              </div>
              <div className="notAuthAndEmpty-btns">
                <Link to="/cart/login">
                  <Button>Sign in to your account</Button>
                </Link>
                <Link to="/cart/register">
                  <Button>Sign up now</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      {items.map((item, i) => {
        const { id, title, price, category, description, images, quantity } =
          item;
        return (
          <Container style={{ paddingTop: "25px" }}>
            {
              isAuth && (
                // test new style

                <Row className="payment-row-2">
                  <Col>
                    <div className="image-div">
                      <Link to={`/products/${id}`}>
                        <img
                          variant="top"
                          // className="img-fluid"
                          src={category.image}
                          alt="product"
                        />
                      </Link>
                    </div>
                    <div className="text-div">
                      <h5>
                        <Link to={`/products/${id}`}> {title} </Link>
                      </h5>
                      <p>{description}</p>
                      <h5 className="price-h5">
                        Price : <span className="old-price">{price}$</span>
                        {Math.round(price - (price * 2) / 100)}
                        <BiDollar size="1.3rem" />
                      </h5>

                      <h6>
                        Quantity : {quantity} {quantity > 1 ? "items" : "item"}{" "}
                      </h6>
                      <h6>
                        Price of Quantity :{" "}
                        {Math.round((price - (price * 2) / 100) * quantity)}{" "}
                        <BiDollar size="1.2rem" />{" "}
                      </h6>
                      <div className="incr-decr-icon">
                        <FaPlus
                          className="incr-icon"
                          onClick={() => updateItemQuantity(id, quantity + 1)}
                        />
                        <FaMinus
                          className="decr-icon"
                          onClick={() => updateItemQuantity(id, quantity - 1)}
                        />
                      </div>
                      <div className="del-save">
                        <h6
                          className="remove-basket-btn"
                          onClick={() => removeItem(id)}
                        >
                          delete
                        </h6>
                        <h6>
                          <Link
                            className="product-details"
                            to={`/products/${id}`}
                          >
                            product details
                          </Link>
                        </h6>
                        <h6 className="remove-basket-btn">
                          {images.length < 2 ? (
                            ""
                          ) : (
                            <SimilarProductsImages data={images} key={id} />
                          )}
                        </h6>
                      </div>
                    </div>
                  </Col>
                  <hr />
                </Row>
              )

              // end test new style
            }
          </Container>
        );
      })}

      {/* <div>
        <h2>Saved Products in Cart Page</h2>
        {data == null ? (
          <p>No Product save to later</p>
        ) : (
          data.map((product) => {
            return <SaveForLater data={product} key={product.id} />;
          })
        )}
      </div> */}
    </>
  );
}

export default Cart;
