import "./Payment.css";
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useCart } from "react-use-cart";
import Login from "../Login/Login";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { FaPlus, FaMinus } from "react-icons/fa";
import { BsDashCircle } from "react-icons/bs";
import { BiDollar } from "react-icons/bi";

function Payment() {
  const isAuth = localStorage.getItem("token");
  const {
    isEmpty,
    items,
    cartTotal,
    removeItem,
    emptyCart,
    updateItemQuantity,
  } = useCart();
  const elements = useElements();
  const stripe = useStripe();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async ($e) => {
    $e.preventDefault();
    const { error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!isEmpty) {
      if (!error) {
        if (sessionStorage.getItem("orders") == null) {
          sessionStorage.setItem("orders", "[]");
        }

        const data = items.map((item) => {
          item.time = new Date().toLocaleTimeString();
          item.date = new Date().toLocaleDateString();
          return item;
        });
        const order = JSON.parse(sessionStorage.getItem("orders"));
        order.push(...data);
        sessionStorage.setItem("orders", JSON.stringify(order));
        toast.success("Payment has been made successfully");
        setSuccess(true);
        emptyCart();
      } else {
        console.log(error.message);
      }
    } else {
      console.log("no product to buy");
    }
  };

  const handleChange = ($e) => {
    setError($e.error ? $e.error.message : "");
    setDisabled($e.empty);
  };

  return isAuth ? (
    <Container className="payment-container">
      <h2 className="checkout-number">
        Checkout ({" "}
        <span>
          {" "}
          {items.length} {items.length > 1 ? "items" : "item"}{" "}
        </span>{" "}
        )
      </h2>
      <Row className="payment-row-1">
        <Col>
          <h5>
            username :{" "}
            {`${localStorage.getItem("first_name")} ${localStorage.getItem(
              "last_name"
            )}`}
          </h5>
          <h5>user email : {localStorage.getItem("email")}</h5>
        </Col>
      </Row>
      {!isEmpty ? (
        <h5 className="delete-all" onClick={() => emptyCart()}>
          delete all items
        </h5>
      ) : (
        ""
      )}
      <hr />

      {isEmpty ? (
        success ? (
          <h2>you bought all products , no more products exist to buy</h2>
        ) : (
          <h2>No Products to buy!</h2>
        )
      ) : (
        items.map((item) => {
          return (
            <Row className="payment-row-2">
              <Col>
                <div className="image-div">
                  <img
                    src={item.category.image}
                    alt="raafat"
                    // className="img-fluid"
                  />
                </div>
                <div className="text-div">
                  <h5>{item.title}</h5>
                  <p>{item.description}</p>
                  <h5>Quantity : {item.quantity}</h5>
                  <h5>
                    Price : {Math.round(item.price - (item.price * 2) / 100)}{" "}
                    <BiDollar />
                  </h5>
                  <div className="incr-decr-icon">
                    <FaPlus
                      className="incr-icon"
                      onClick={() =>
                        updateItemQuantity(item.id, item.quantity + 1)
                      }
                    />
                    <FaMinus
                      className="decr-icon"
                      onClick={() =>
                        updateItemQuantity(item.id, item.quantity - 1)
                      }
                    />
                  </div>
                  <div className="del-save">
                    <h6
                      className="remove-basket-btn"
                      onClick={() => removeItem(item.id)}
                    >
                      delete
                    </h6>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })
      )}

      <hr />
      <Row className="payment-row-3">
        <Col>
          <h5>Payment Method :</h5>

          {!isEmpty ? (
            !success ? (
              <form onSubmit={handleSubmit}>
                <CardElement onChange={handleChange} />
                <h3>
                  Order Total :{" "}
                  <span>
                    {Math.round(cartTotal - (cartTotal * 2) / 100)} <BiDollar />{" "}
                  </span>
                </h3>
                <button className="pay-btn" disabled={disabled || success}>
                  Buy Now
                </button>
                {error && <small className="payment-error">{error}</small>}
              </form>
            ) : (
              <div>
                <h2>Successfully purchased products</h2>
              </div>
            )
          ) : !success ? (
            <h2>
              {/* No Product to buy! */}
              <BsDashCircle />
            </h2>
          ) : (
            <h2>Successfully purchased products</h2>
          )}
        </Col>
      </Row>
    </Container>
  ) : (
    <Login />
  );
}

export default Payment;
