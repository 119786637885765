import "./Footer.css";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
  return (
    <div className="footer-section">
      <Container>
        <Row>
          <Col className="text-footer">
            <div>
              &copy; 2022 <span>React eCommerce</span> All Right Reserved
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
